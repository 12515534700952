import React from 'react';
import {Link} from 'gatsby';
import urlBuilder from '@sanity/image-url';
import {MdToday} from 'react-icons/md';
import {processDate, summarizeDates} from '../../../utils/processDate';
import {excerpt} from '../../../utils/excerpt';
import InfoCard from '../eventShow/infoCard';
import SectionWrapper from '../../layout/sectionWrapper';
import SectionContainer from '../../layout/sectionContainer';
import {flatMap} from 'lodash';
import {formatDepartmentName} from '../../../utils/formatDepartmentName';

const urlFor = (source) =>
  urlBuilder({
    projectId: process.env.GATSBY_SANITY_PROJECT_ID,
    dataset: process.env.GATSBY_SANITY_DATASET,
  }).image(source);

export const formatTimeSlot = (timeSlots, shortFormat = false) => {
  const distinctDates = new Set(timeSlots.map((timeSlot) => timeSlot?.startDate));
  const daysCount = distinctDates.size;
  let timeSlotInfo = '';
  if (timeSlots.length === 0) {
    timeSlotInfo = "Contacter l'organisateur pour obtenir les horaires"
  } else if (timeSlots.length === 1) {
    timeSlotInfo = processDate(timeSlots[0], shortFormat);
  } else if (daysCount === 1) {
    timeSlotInfo = processDate(timeSlots[0], shortFormat, false)
  } else {
    timeSlotInfo = 'Horaires Multiples'
  }
  return <div><span>{timeSlotInfo}</span></div>;
}

export const EventMiniCard = ({event}) => {
  if (!event.slug) return null;
  const timeSlotsPerLocation = event.village.length ? event.village.reduce((acc, village) => {
    acc[village.title] = village.timeSlots;
    return acc;
  }, {}) : {"address": event.timeSlots};
  const timeSlots = flatMap(timeSlotsPerLocation, (timeSlots) => timeSlots);
  const isScolaire = event.audience.length > 0;
  const department = formatDepartmentName(event.department.name);
  const timeSlotInfo = summarizeDates(timeSlots);
  return (
    <Link to={`/${department}/${event.slug.current}`}>
      <div className="relative flex items-center border border-gray-200 rounded-lg">
        {event.image?.asset?.url && (
          <div
            style={{
              backgroundImage: `url(${urlFor(event.image.asset.url)})`,
            }}
            className="w-full h-32 bg-center bg-cover rounded-lg"
          />
        )}
        {!event.image?.asset?.url && (
          <div className="w-full h-32 bg-gray-800 bg-center bg-cover rounded-lg" />
        )}
        <div
          className="absolute inset-0 rounded-lg group"
          style={{backgroundColor: 'rgba(0,0,0,.5)'}}
        >
          <div className="flex flex-col items-center justify-center h-full p-3">
            <h4 className="text-base leading-tight text-center text-white transform group-hover:scale-105">
              {excerpt(event.title, 55)}{isScolaire ? ' (public scolaire)' : ''}
            </h4>
            <span className="leading-none text-white">---</span>
            <div className="gap-1">
              <div className="text-xs text-center text-white uppercase">{event.department.name}</div>
              <div className="flex items-center px-2 space-x-2 text-sm text-white">
                <span className="text-base">
                  <MdToday />
                </span>
                {timeSlotInfo}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

const EventsListSection = ({title, subtitle, events}) => {
  return (
    <SectionWrapper>
      <SectionContainer customClasses="pb-12" id="village-events">
        <InfoCard title={title ?? "Les événements du Village"} customClasses="">
          {subtitle && <p className="mb-4 text-xl">{subtitle}</p>}
          <div className="text-lg leading-relaxed">
            {events.length > 0 && (
              <div className="grid grid-cols-1 gap-4 mt-4 sm:mt-0 md:grid-cols-2 lg:grid-cols-3">
                {events.map((event) => {
                  return (
                    <EventMiniCard
                      event={event}
                      key={event.id}
                    />
                  );
                })}
              </div>
            )}
            {events.length <= 0 && (
              <div className="py-10 text-center">
                <p>Aucun événement enregistré pour le moment...</p>
              </div>
            )}
          </div>
        </InfoCard>
      </SectionContainer>
    </SectionWrapper>
  );
};

export default EventsListSection;
