import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout/layout';
import HeroSection from '../components/sections/villageShow/heroSection';
import DescriptionSection from '../components/sections/eventShow/descriptionSection';
import EventsListSection from '../components/sections/villageShow/eventsListSection';
import OpeningHours from '../components/sections/eventShow/openingHours';

const VillageShow = ({ data }) => {
  const village = data.sanityVillage;
  const isScolaire = typeof window !== 'undefined' && window.location.search.includes('scolaire');
  const events = data.allSanityEvent.edges.map((edge) => edge.node).filter((event) => {
    return isScolaire ? event.education : !event.education;
  });
  const timeSlotsPerLocation = { "address": village.timeSlots}
  return (
    <Layout>
      <HeroSection village={village} />
      <DescriptionSection description={village._rawDescription} />
      <EventsListSection events={events} />
      {village.timeSlots.length > 1 && (
        <OpeningHours timeSlotsPerLocation={timeSlotsPerLocation} />
      )}
    </Layout>
  );
};

export default VillageShow;

export const query = graphql`
  query($villageId: String!) {
    sanityVillage(id: { eq: $villageId }) {
      _id
      title
      address
      department {
        name
      }
      _rawDescription
      eventCanceled
      timeSlots {
        _key
        endDate
        endTime
        startDate
        startTime
        isEducation
      }
      image {
        asset {
          url
          gatsbyImageData(width: 1200)
        }
        hotspot {
          x
          y
        }
      }
    }
    allSanityEvent(
      filter: {
        village: {elemMatch: {id: {eq: $villageId}}}
      }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          village {
            id
            title
            timeSlots {
              endDate
              endTime
              startDate
              startTime
            }
          }
          education
          department {
            name
          }
          featured
          eventCanceled
          _rawDescription
          description {
            children {
              text
            }
          }
          theme {
            id
            name
          }
          timeSlots {
            endDate
            endTime
            startDate
            startTime
          }
          format {
            id
            name
            formatIcon {
              asset {
                gatsbyImageData(width: 500)
              }
            }
          }
          image {
            asset {
              url
              gatsbyImageData(width: 1200)
            }
            hotspot {
              x
              y
            }
          }
          audience {
            id
            name
          }
          audienceCustom {
            from
            to
          }
        }
      }
    }
  }
`;
